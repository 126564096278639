import React, { useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { dashboardPagesMenu, demoPagesMenu } from '../../menu';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import dummyimage from '../../assets/download (2).png'

const User = () => {
	const { userData, setUser } = useContext(AuthContext);
const name = localStorage.getItem("facit_authUsername")
const userLogin =JSON.parse(localStorage.getItem("login") as any)
const RoleData =JSON.parse(localStorage.getItem("RoleData") as any)


	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle() as any;
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img
						srcSet={userData?.srcSet}
						src={dummyimage}
						alt='Avatar'
						width={128}
						height={128}
					/>
				</div>
				<div className='user-info'>
					<div className='user-name d-flex align-items-center'>
						{name}
						{/* {`${userData?.name} ${userData?.surname}`} */}
						<Icon icon='Verified' className='ms-1' color='info' />
					</div>
					{/* <div className='user-sub-title'>{userLogin?.name}</div> */}
				</div>
			</div>
			<DropdownMenu>
			<DropdownItem>
					<Button
						icon='ModeEdit'
						onClick={() =>
							navigate(
								`../${dashboardPagesMenu.modes.path}`,
							)
						}>
						Mode
					</Button>
				</DropdownItem>
			<DropdownItem>
					<Button
						icon='ModeEdit'
						onClick={() =>
							navigate(
								`../${dashboardPagesMenu.Roles.path}`,
							)
						}>
						Role
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon='ModeEdit'
						onClick={() =>
							navigate(
								`../${dashboardPagesMenu.TagCategory.path}`,
							)
						}>
						Service
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon='AccountBox'
						onClick={() =>
							navigate(
								`/admin-profile/${userLogin?._id}`,
							)
						}>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>

					
					<div role='presentation'
					 className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../${dashboardPagesMenu.modes.path}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='ModeEdit' className='navigation-icon' />
									<span className='navigation-text'>
										{t("Modes ") as ReactNode}
									</span>
								</span>
							</span>
						</div>


						<div
						role='presentation'
					 className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../${dashboardPagesMenu.Roles.path}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='EditCalendar' className='navigation-icon' />
									<span className='navigation-text'>
										{t("Roles ") as ReactNode}
									</span>
								</span>
							</span>
						</div>

						<div role='presentation'
					 className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../${dashboardPagesMenu.Permission.path}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='ModeEdit' className='navigation-icon' />
									<span className='navigation-text'>
										{t("Permissions") as ReactNode}
									</span>
								</span>
							</span>
						</div>

						<div
						role='presentation'
					 className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../${dashboardPagesMenu.TagCategory.path}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='EditCalendar' className='navigation-icon' />
									<span className='navigation-text'>
										{t("Tag Category") as ReactNode}
									</span>
								</span>
							</span>
						</div>
						<div
						role='presentation'
					 className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../${dashboardPagesMenu.Tag.path}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='EditCalendar' className='navigation-icon' />
									<span className='navigation-text'>
										{t("Tag's") as ReactNode}
									</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`/admin-profile/${userLogin?._id}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Profile') as ReactNode}
									</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus
											? (t('menu:DarkMode') as ReactNode)
											: (t('menu:LightMode') as ReactNode)}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								if (setUser) {
									setUser('');
								}
								navigate(`../${demoPagesMenu.login.path}`);
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
